// Generated by Framer (1c1e241)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Rlshc_B3v"];

const serializationHash = "framer-4j9Cz"

const variantClassNames = {Rlshc_B3v: "framer-v-ris4u1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Rlshc_B3v", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-4j9Cz", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ris4u1", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Rlshc_B3v"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-z1ozqo"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Gk0StJ0M4-shape"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 679 345\"><path d=\"M 5 340 L 339.5 5.5 L 674 340\" fill=\"transparent\" stroke-width=\"10\" stroke=\"rgb(170, 170, 170)\"></path></svg>"} svgContentId={2411824415} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-4j9Cz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4j9Cz .framer-lj7gg5 { display: block; }", ".framer-4j9Cz .framer-ris4u1 { height: 345px; overflow: hidden; position: relative; width: 679px; }", ".framer-4j9Cz .framer-z1ozqo { flex: none; height: 345px; left: calc(49.92636229749634% - 679px / 2); position: absolute; top: calc(49.85507246376814% - 345px / 2); width: 679px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 345
 * @framerIntrinsicWidth 679
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerUOk95qFUj: React.ComponentType<Props> = withCSS(Component, css, "framer-4j9Cz") as typeof Component;
export default FramerUOk95qFUj;

FramerUOk95qFUj.displayName = "2";

FramerUOk95qFUj.defaultProps = {height: 345, width: 679};

addFonts(FramerUOk95qFUj, [])